.submitButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #509b4e;
  border: none;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  width: auto;
}

.submitButton:hover {
  background-color: #75b373;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

@media only screen and (min-width: 768px) {
  .submitButton {
    font-size: 18px;
  }
}
