.footer {
  height: 40px;
  // position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  //   width: 100%;
  padding: 20px;
  justify-content: space-evenly;
  box-shadow: 0px -6px 8px rgb(175, 175, 175);
  height: auto;
  // margin-top: 100px;

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      transition: all 0.3s linear;
      color: black;
      margin: 3px;
      text-decoration: none !important;
    }

    a:hover {
      color: #4e9a9b;
    }
  }

  .up {
    color: black;
    transition: all 0.3s linear;
  }
  .up:hover {
    color: #4e9a9b;
  }
}

.copy {
  background-color: #ffffff;
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .footer {
    padding: 10px;
    justify-content: space-between;

    .links {
      flex-direction: row;
      justify-content: space-evenly;

      a {
        margin: 3px 4px;
      }
    }

    .up {
      margin-left: 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 10px;
    justify-content: space-between;

    .links {
      flex-direction: row;
      justify-content: space-evenly;

      a {
        margin: 3px 20px;
      }
    }

    .up {
      margin-left: 20px;
    }
  }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
