.block {
  transition: all 0.1s linear;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: auto;
  max-width: 100%;

  border-radius: 15px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  padding: 20px;
  margin: 30px;

  .question {
    // margin: 20px;
    font-size: 16px;
    font-weight: 500;
  }
}

.block:hover {
  transform: translateY(-5px);
  // background-color: #b0b7bd;
  box-shadow: 4px 4px 10px rgba(96, 96, 96, 0.7);
}

.innerContainer {
  position: relative;
  text-align: center;
  width: 50%;
  height: 70%;
  border: none;
}

.button {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: rgb(39, 39, 39);
  border: none;
  width: 90%;
  padding: 20px 30px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.button:hover {
  background-color: rgb(101, 101, 101);
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.submitButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #509b4e;
  border: none;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  width: 100px;
}

.submitButton:hover {
  background-color: #54af52;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.cancelButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #3b8283;
  border: none;
  width: 100px;
  padding: 20px 20px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.cancelButton:hover {
  background-color: #49aaac;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.notApplicableButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: rgb(39, 39, 39);
  border: none;
  width: 90%;
  padding: 20px 30px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.notApplicableButton:hover {
  background-color: rgb(101, 101, 101);
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.thanksContainer {
  transition: all 0.1s linear;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  h2 {
    margin: 0 20px;
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .viewInProfile {
    transition: all 0.1s linear;
    cursor: pointer;
    background-color: #4e9a9b;
    color: #fff;
    border: none;
    width: 80%;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    padding-right: 20px;

    .chevRight {
      transition: all 0.1s linear;
      position: relative;
      top: 6.3px;
      // transform: translateY(-50%);
      margin: 0px 0px;
    }
  }

  .viewInProfile:hover {
    background-color: #49aaac;
    transform: scale(1.01);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);

    .chevRight {
      transform: translateX(5px);
    }
  }
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .thanksContainer {
    .viewInProfile {
      width: unset;
      border: none;
      padding: 20px 30px;
      border-radius: 5px;
      font-size: 18px;
      box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    }
  }
}

@media only screen and (max-width: 479px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 90%;
    height: 50%;
    border: none;
  }
}

@media only screen and (max-width: 820px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 85%;
    height: 50%;
    border: none;
  }
}

@media only screen and (min-width: 768px) {
  .block {
    max-width: 300px;
    height: 200px;

    .question {
    }
  }

  .button {
    color: whitesmoke;

    width: 200px;
    padding: 20px 30px;

    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
