* {
  font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.rulesContainer {
  // max-width: 1080px;
  margin: 0px auto;
  // padding-top: 120px;
  text-align: center;

  .rules {
    margin-top: 20px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .rules2 {
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }
}

.topButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .randomButton {
    transition: all 0.1s linear;
    margin-top: 20px;
    margin-left: 8px;
    cursor: pointer;
    color: whitesmoke;
    background-color: #509b4e;
    border: none;
    padding: 15px 25px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  }

  .randomButton:hover {
    background-color: #54af52;
    transform: translateY(-2px);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
  }
}

.submitButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #509b4e;
  border: none;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  width: 100px;
}

.submitButton:hover {
  background-color: #54af52;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.cancelButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #4e9a9b;
  border: none;
  width: 100px;
  padding: 20px 20px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.cancelButton:hover {
  background-color: #49aaac;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.container {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 120px;
  text-align: center;
  // height: auto;
  // padding-bottom: 40px;
  // min-height: 100vh !important;
  // position: relative !important;

  .padding {
    padding: 30px;
    background-color: #fff;

    h4 {
      margin: 5px;
    }

    h2 {
      font-weight: 300;
      font-size: 25px;

      span {
        display: inline-block;
      }
    }
    button {
      //   position: relative;
      transition: all 0.1s linear;
      cursor: pointer;
      background-color: #525ac2;
      color: #fff;
      border: none;
      width: 80%;
      padding: 20px 30px;
      border-radius: 5px;
      font-size: 18px;
      box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
      padding-right: 20px;

      .eye {
        position: relative;
        top: 5px;
        // transform: translateY(-50%);
        margin: 0px 10px;
      }
    }

    button:hover {
      background-color: #5660ee;
      transform: scale(1.01);
      box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
    }

    .line {
      max-height: 1px;
      background-color: rgb(230, 230, 230);
      padding: 1px;
      //   position: relative;
      //   left: 0px;
      width: 100%;
    }

    p {
      color: rgb(34, 34, 34);
      font-size: 19px;
      font-weight: 400;
      line-height: 28px;
    }

    .textFlex {
      .switch {
        /* display: flex; */
        /* font-size: 20px; */
        /* font-family: 'Montserrat' !important; */
        display: block !important;
        // transform: scale(1.5);
        .switchText {
          font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana,
            sans-serif !important;
        }
        .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1 {
          font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana,
            sans-serif !important;
        }
      }
    }
  }
}

.thanksContainer {
  transition: all 0.1s linear;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  h2 {
    margin: 0 20px;
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .viewInProfile {
    transition: all 0.1s linear;
    cursor: pointer;
    background-color: #4e9a9b;
    color: #fff;
    border: none;
    width: 80%;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    padding-right: 20px;

    .chevRight {
      transition: all 0.1s linear;
      position: relative;
      top: 6.3px;
      // transform: translateY(-50%);
      margin: 0px 0px;
    }
  }
  .viewInProfile:hover {
    background-color: #49aaac;
    transform: scale(1.01);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);

    .chevRight {
      transform: translateX(5px);
    }
  }
}

.containerInfo {
  max-width: 1080px;
  margin: 0px auto;
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  text-align: center;
  // height: auto;
  border-top: #525ac2 solid 5px;
  // padding-bottom: 40px;

  .padding {
    padding: 30px;
    background-color: #fff;

    .textBox {
      margin-bottom: 30px;
      .textFlex {
        display: flex;
        flex-direction: column;

        h3 {
          font-weight: 300;
          font-size: 25px;
        }

        h2 {
          font-weight: 500;
          font-size: 25px;
          margin: 10px;
          border: dashed grey 1px;
          padding: 10px;
        }
      }
    }

    h3 {
      font-weight: 300;
      font-size: 25px;

      margin-bottom: 20px;
    }

    h2 {
      font-weight: 400;
      font-size: 14px;
      margin: 10px 0;
      margin-bottom: 40px;
      line-height: 25px;
    }

    .edit {
      cursor: pointer;
      transition: all 0.1s linear;
      background-color: #529c51;
      padding: 5px 10px;
      padding-bottom: 15px;
      text-align: center;
      width: 90%;
      border-radius: 5px;
      color: #fff;

      .editIcon {
        position: relative;
        top: 5px;
        margin: 0;
        margin-right: -7px;
      }
    }

    .edit:hover {
      background-color: #54af52;
    }
  }
}

.containerOther {
  max-width: 1080px;
  margin: auto auto;
  margin-top: 30px;
  text-align: center;
  // height: auto;
  // border-top: #525ac2 solid 5px;
  // padding-bottom: 40px;

  .padding {
    padding: 10px;
    margin-bottom: 30px;
    background-color: #525ac2;
    display: flex;
    align-items: center;

    h3 {
      font-weight: 300;
      font-size: 20px;
      color: #fff;
      margin-left: 10px;
    }

    .addCircle {
      transition: all 0.2s linear;
      cursor: pointer;
      color: #fff;
      // width: 100%;
      font-size: 70px;
      margin: auto 0;
      margin-left: 20px;
      // margin-top: 20px;
    }
  }

  .padding:hover {
    background-color: #49aaac;
  }

  .userPadding {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 30px;
    border: #525ac2 solid 5px;
  }
}

.innerContainer {
  position: relative;
  text-align: center;
  width: 50%;
  height: 70%;
  border: none;
}

.button {
  transition: all 0.1s linear;
  cursor: pointer;
  background-color: #4e9a9b;
  color: #fff;
  border: none;

  padding: 20px 30px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  padding-right: 20px;
  margin-top: 20px;
  width: 100%;

  .chevRight {
    transition: all 0.1s linear;
    position: relative;
    top: 6.3px;
    // transform: translateY(-50%);
    margin: 0px 0px;
  }
}
.button:hover {
  background-color: #49aaac;
  transform: scale(1.01);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.tabs {
  min-height: 100vh !important;
  position: relative !important;
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .container {
    max-width: 1080px;
    margin: 0px auto;
    padding-top: 120px;
    text-align: left;
    // height: auto;
    // padding-bottom: 40px;
    .padding {
      padding: 30px;
      background-color: #fff;

      h2 {
        font-weight: 200;
        font-size: 40px;

        span {
          display: inline-block;
        }
      }

      button {
        width: unset;
        border: none;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 18px;
        box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
      }

      .line {
        max-height: 1px;
        background-color: rgb(230, 230, 230);
        padding: 1px;
        //   position: relative;
        //   left: 0px;
        width: 50%;
      }

      p {
        color: rgb(34, 34, 34);
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
      }

      //   .textFlex {
      //     .switch {
      //       /* display: flex; */
      //       /* font-size: 20px; */
      //       /* font-family: 'Montserrat' !important; */
      //       display: flex !important;
      //       transform: none !important;
      //       padding: 0;
      //       padding-left: 20px;
      //       padding-top: 5px;
      //       .theswitch {
      //         padding: 0 !important;
      //       }
      //     }
      //   }
    }
  }

  .containerInfo {
    max-width: 1080px;
    margin: 0px auto;
    margin-top: 30px;
    text-align: left;
    // height: auto;
    border-top: #525ac2 solid 5px;
    // padding-bottom: 40px;

    .padding {
      padding: 30px;
      background-color: #fff;
      position: relative;

      .textBox {
        .textFlex {
          display: flex;
          flex-direction: row;
          h3 {
            font-weight: 300;
            font-size: 25px;
            margin: 8px;
          }

          h2 {
            font-weight: 500;
            font-size: 25px;
            margin: 0;
            border: none;
            padding: none;
          }
        }
      }

      h3 {
        font-size: 25px;
      }
      h2 {
        font-weight: 500;
        font-size: 18px;
        white-space: pre-line;
      }

      .edit {
        position: absolute;
        width: 100px;
        bottom: 10px;
        right: 10px;

        .editIcon {
        }
      }
    }
  }

  .containerOther {
    max-width: 1080px;
    margin: 0px auto;
    margin-top: 30px;
    text-align: center;
    // height: auto;
    // border-top: #525ac2 solid 5px;
    // padding-bottom: 40px;

    .padding {
      h3 {
        font-weight: 300;
        font-size: 25px;
        margin: 10px;
        color: #fff;
      }
      p {
        margin: 5px;
      }
    }
  }

  .thanksContainer {
    .viewInProfile {
      width: unset;
      border: none;
      padding: 20px 30px;
      border-radius: 5px;
      font-size: 18px;
      box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    }
  }

  .button {
    width: unset;
    border: none;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    margin-left: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 90%;
    height: 50%;
    border: none;
  }
}

@media only screen and (max-width: 820px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 85%;
    height: 50%;
    border: none;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
