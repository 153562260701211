.container {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 120px;
  text-align: center;
  min-height: 100vh !important;
  position: relative !important;
  // height: 100vh;
  // padding-bottom: 40px;

  // .pagination {
  //   display: flex;
  //   flex-basis: fit-content;
  //   max-width: 400px;
  //   justify-content: space-between;
  //   padding: 0px;
  //   margin: 30px auto;

  //   li {
  //     list-style-type: none;
  //     padding: 5px;

  //     a {
  //     }
  //   }

  //   li:hover {
  //     cursor: pointer;
  //     background-color: lightgray;
  //   }

  //   .page-item.active .page-link {
  //     z-index: 3;
  //     color: #fff;
  //     background-color: #0d6efd;
  //     border-color: #0d6efd;
  //   }

  //   li:active,
  //   li:focus,
  //   li:target {
  //     font-weight: 600;
  //     color: #4e9a9b;
  //   }

  //   li:disabled {
  //     color: lightgray;
  //   }
  // }

  .rules {
    margin-top: 20px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .rules2 {
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .topButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .randomButton {
      transition: all 0.1s linear;
      margin-top: 20px;
      margin-left: 8px;
      cursor: pointer;
      color: whitesmoke;
      background-color: #509b4e;
      border: none;
      padding: 15px 25px;
      border-radius: 5px;
      font-size: 18px;
      box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    }

    .randomButton:hover {
      background-color: #54af52;
      transform: translateY(-2px);
      box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
    }
  }
}

.innerContainer {
  position: relative;
  text-align: center;
  width: 50%;
  height: 70%;
  border: none;
}

.button {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: rgb(39, 39, 39);
  border: none;
  width: 90%;
  padding: 20px 30px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.button:hover {
  background-color: rgb(101, 101, 101);
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.submitButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #509b4e;
  border: none;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  width: 100px;
}

.submitButton:hover {
  background-color: #88ba86;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.cancelButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #3b8283;
  border: none;
  width: 100px;
  padding: 20px 20px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.cancelButton:hover {
  background-color: #8bbfc0;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.thanksContainer {
  transition: all 0.1s linear;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  h2 {
    margin: 0 20px;
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .viewInProfile {
    transition: all 0.1s linear;
    cursor: pointer;
    background-color: #4e9a9b;
    color: #fff;
    border: none;
    width: 80%;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    padding-right: 20px;

    .chevRight {
      transition: all 0.1s linear;
      position: relative;
      top: 6.3px;
      // transform: translateY(-50%);
      margin: 0px 0px;
    }
  }
  .viewInProfile:hover {
    background-color: #49aaac;
    transform: scale(1.01);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);

    .chevRight {
      transform: translateX(5px);
    }
  }
}

@media only screen and (min-width: 414px) {
  .thanksContainer {
    .viewInProfile {
      width: unset;
      border: none;
      padding: 20px 30px;
      border-radius: 5px;
      font-size: 18px;
      box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    }
  }
}

@media only screen and (max-width: 479px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 90%;
    height: 50%;
    border: none;
  }
}

@media only screen and (max-width: 820px) {
  .innerContainer {
    position: relative;
    text-align: center;
    width: 85%;
    height: 50%;
    border: none;
  }
}

@media only screen and (min-width: 768px) {
  .button {
    color: whitesmoke;

    width: 200px;
    padding: 20px 30px;

    font-size: 18px;
  }

  .container {
    .topButtons {
      display: flex;
      flex-direction: row;

      .randomButton {
        // margin-top: 10px;
        margin-left: 50px;

        padding: 10px 20px;
        border-radius: 5px;
        font-size: 18px;
        box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
        width: auto;
      }

      .randomButton:hover {
        transform: translateY(-2px);
        box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
      }
    }
  }
}
