.card {
  //   max-width: 45%;
  margin: 100px 0px;

  .contentBlock {
    text-align: center;
    width: 100%;

    .textBlock {
      margin: 0;

      h1 {
        margin: 0;
        font-size: 50px;
        font-weight: 100;
      }

      p {
        width: 100%;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 35px;
      }

      button {
        transition: all 0.1s linear;
        cursor: pointer;
        background-color: #fff;
        border: none;
        width: 80%;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 18px;
        box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
      }

      button:hover {
        // background-color: honeydew;
        transform: translateY(-2px);
        box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
      }
    }

    .imageBlock {
      margin: 30px 0px;
      //   height: 400px;
      //   overflow: hidden;

      img {
        // max-width: 450px;
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
}

@media only screen and (min-width: 768px) {
  .card {
    .contentBlock {
      text-align: left;
      display: flex;
      justify-content: space-between;

      .textBlock {
        width: 40%;
        margin: 0;

        h1 {
          margin: 0;
          font-size: 50px;
          font-weight: 100;
        }

        p {
          width: 100%;
          margin-bottom: 50px;
          font-size: 20px;
          line-height: 35px;
        }

        button {
          width: unset;
          border: none;
          padding: 20px 30px;
          border-radius: 5px;
          font-size: 18px;
          box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
        }
      }

      .imageBlock {
        margin: 0;
        margin-left: 50px;

        img {
          width: unset;
          // margin-top: unset;
          max-width: 450px;
        }
      }
    }
  }

  .card:nth-child(odd) {
    .contentBlock {
      flex-direction: row-reverse;
      text-align: right;

      .imageBlock {
        margin: 0;
        margin-right: 50px;

        img {
          width: unset;
          max-width: 450px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
