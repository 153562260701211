.main {
  margin: 0 20px 20px 20px;
  // margin-top: -40px;
  padding-top: 60px;
  .container {
    max-width: 1080px;
    margin: 0px auto;
    .pageTitle {
      font-size: 60px;
      font-weight: 400;
      text-align: center;
      //   font-style: italic;
    }
  }
}
