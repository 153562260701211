.container {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 120px;
  padding-bottom: 60px;
  text-align: center;
  min-height: 100vh !important;
  position: relative !important;

  // padding-bottom: 40px;

  div {
    padding: 30px;
    background-color: #fff;

    h2 {
      font-weight: 300;
      font-size: 25px;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }
    }

    .line {
      max-height: 1px;
      background-color: rgb(230, 230, 230);
      padding: 1px;
      //   position: relative;
      //   left: 0px;
      width: 100%;
    }

    p {
      color: rgb(34, 34, 34);
      font-size: 19px;
      font-weight: 400;
      line-height: 28px;
      white-space: pre-line;
    }
  }
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .container {
    max-width: 1080px;
    margin: 0px auto;
    padding-top: 120px;
    text-align: left;

    // padding-bottom: 40px;
    div {
      padding: 30px;
      background-color: #fff;

      h2 {
        font-weight: 200;
        font-size: 40px;

        span {
          display: inline-block;
        }
      }

      .line {
        max-height: 1px;
        background-color: rgb(230, 230, 230);
        padding: 1px;
        //   position: relative;
        //   left: 0px;
        width: 50%;
      }

      p {
        color: rgb(34, 34, 34);
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
