.container {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 120px;
  text-align: left;
  min-height: 100vh !important;
  position: relative !important;
  // padding-bottom: 40px;

  div {
    padding: 30px;
    background-color: #fff;

    h2 {
      font-weight: 200;
    }

    .line {
      max-height: 1px;
      background-color: rgb(230, 230, 230);
      padding: 1px;
      //   position: relative;
      //   left: 0px;
      width: 50%;
    }

    p {
      color: rgb(34, 34, 34);
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
  }
}
