.container {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 120px;
  text-align: left;
  min-height: 100vh !important;
  position: relative !important;
  text-align: center;
  // padding-bottom: 40px;

  .divContainer {
    padding: 10px;
    background-color: #fff;

    h2 {
      font-weight: 200;
    }

    .line {
      max-height: 1px;
      background-color: rgb(230, 230, 230);
      padding: 1px;
      //   position: relative;
      //   left: 0px;
      width: 100%;
    }

    .textBox {
      margin-bottom: 30px;

      .textFlex {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-weight: 300;
          font-size: 25px;
        }

        h2 {
          font-weight: 500;
          font-size: 17px;
          //   margin: 10px;
          border: dashed grey 1px;
          padding: 10px;
        }
        .switch {
          /* display: flex; */
          /* font-size: 20px; */
          /* font-family: 'Montserrat' !important; */
          display: block !important;
          transform: scale(1.5);
          .switchText {
            font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana,
              sans-serif !important;
          }
          .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1 {
            font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana,
              sans-serif !important;
          }
        }
        .submitButton {
          transition: all 0.1s linear;
          cursor: pointer;
          background-color: #4e9a9b;
          color: #fff;
          border: none;
          // width: 30%;
          padding: 18px 20px;
          border-radius: 5px;
          font-size: 18px;
          box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
          // padding-right: 20px;
        }

        .submitButton:hover {
          background-color: #49aaac;
          // transform: translateY(-5px);
          // box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
        }
        small {
          cursor: pointer;
          margin-top: 20px;
          color: blue;
        }

        .helperText {
          color: #f44336;
          font-size: 14px;
          width: 300px;
        }
      }

      .delete {
        cursor: pointer;
        transition: all 0.1s linear;
        background-color: #b43737;
        padding: 5px 10px;
        padding-bottom: 15px;
        text-align: center;
        width: 90%;
        border-radius: 5px;
        color: #fff;
        margin: 0 auto;
        margin-top: 100px;

        .deleteIcon {
          position: relative;
          top: 5px;
          margin: 0;
          margin-right: -7px;
        }
      }
      .delete:hover {
        background-color: #c43333;
      }
    }

    p {
      color: rgb(34, 34, 34);
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
  }
}

.thanksContainer {
  transition: all 0.1s linear;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 20px;
    font-weight: 500;
    color: black;
  }

  h2 {
    margin: 0 20px;
    margin-bottom: 30px;
    color: grey;
    font-size: 16px;
    font-weight: 400;
  }

  .button {
    transition: all 0.1s linear;
    cursor: pointer;
    background-color: #4e9a9b;
    color: #fff;
    border: none;
    width: 80%;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    padding-right: 20px;
  }

  .buttonDelete {
    transition: all 0.1s linear;
    cursor: pointer;
    background-color: #c43333;
    color: #fff;
    border: none;
    width: 80%;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    padding-right: 20px;
    margin-top: 20px;
  }

  .button:hover {
    background-color: #49aaac;
    transform: translateY(-5px);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
  }

  .buttonDelete:hover {
    background-color: #c43333;
    transform: translateY(-5px);
    box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
  }

}

.subscription {
  padding: 10px;
  border-radius: 20px;
  color: white;
  background-color: #49aaac;
}


@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 1080px;
    margin: 0px auto;
    padding-top: 120px;
    text-align: left;

    .divContainer {
      padding: 30px;
      .line {
        max-height: 1px;
        background-color: rgb(230, 230, 230);
        padding: 1px;
        //   position: relative;
        //   left: 0px;
        width: 50%;
      }

      .textBox {
        .textFlex {
          display: flex;
          flex-direction: row;
          padding: 30px;
          h3 {
            font-weight: 300;
            font-size: 25px;
            margin: 8px;
          }

          h2 {
            font-weight: 500;
            font-size: 25px;
            margin: 0;
            border: none;
            padding: none;
          }

          .switch {
            /* display: flex; */
            /* font-size: 20px; */
            /* font-family: 'Montserrat' !important; */
            display: flex !important;
            transform: none !important;
            padding: 0;
            padding-left: 20px;
            padding-top: 5px;
            .theswitch {
              padding: 0 !important;
            }
          }

          small {
            margin: 0;
            margin-left: 20px;
          }
        }

        .delete {
          width: 250px;
          margin: 0 auto;
          margin-top: 200px;

          .deleteIcon {
          }
        }
      }
    }
  }

  .thanksContainer {
    h1 {
      font-size: 30px;
      font-weight: 500;
      color: black;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }

    h2 {
      margin: 0 20px;
      margin-bottom: 30px;
      color: grey;
      font-size: 16px;
      font-weight: 400;
    }

    .button {
    }

    .buttonDelete {
    }

    .viewInProfile:hover {
    }
  }
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
