.containerHeader {
  position: relative !important;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    width: 50%;
    font-size: 18px;
    font-weight: 500!important;
  }
  
}

.container {
  // max-width: 1080px;
  width: max-content;
  margin: 0px auto;
  padding-top: 20px;
  text-align: left;
  min-height: 100vh !important;
  position: relative !important;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 600px){
    flex-direction: column;
  }
  
  .subscriptionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 250px;
    height: 300px;
    border: 1px solid #EAEAEA;
    border-radius: 12px;

    .subscriptionPrice {
      font-size: 18px;
      margin: 15px 0;

      span {
        font-size: 28px;
        font-weight: 600;
      }
      
    }

    .subscriptionCardTitle {
      background-color: #D9DCE2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      height: 20%;
      color: #408989;
      font-weight: 500;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .subscriptionCardFeatures {
      font-size: 16px;
      height: 50%;
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      span {
        margin-bottom: 8px;
      }
      
    }

    .subscriptionCardButton {
      width: 80%;
      height: 12.5%;
      background-color: transparent;
      color: #408989;
      border: 1px solid #408989;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      padding: 2px 6px;
      margin-bottom: 10px;
      transition: all .5s;

      &:hover {
        cursor: pointer;
        border: 1px solid #408989;
        color: white;
        background-color: #408989;
      }
    }

    .subscriptionCardButtonSelected {
      width: 80%;
      height: 12.5%;
      background-color: #408989;
      color: white;
      border: 1px solid #408989;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      padding: 2px 6px;
      margin-bottom: 10px;
      transition: all .5s;

      &:hover {
        cursor: pointer;
        border: 1px solid #408989;
        color: white;
        background-color: #408989;
      }
    }
    
  }
 
}