.containerQuestions {
  max-width: 1080px;
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  text-align: center;
  height: auto;
  border-top: #4e9a9b solid 5px;
  // padding-bottom: 40px;

  .padding {
    padding: 30px;
    background-color: #fff;

    .textBox {
      margin-bottom: 30px;
      .textFlex {
        display: flex;
        flex-direction: column;

        h3 {
          font-weight: 300;
          font-size: 25px;
        }

        h2 {
          font-weight: 500;
          font-size: 25px;
          margin: 10px;
          border: dashed grey 1px;
          padding: 10px;
        }
      }
    }
    .viewInProfile {
      transition: all 0.1s linear;
      cursor: pointer;
      background-color: #4e9a9b;
      color: #fff;
      border: none;
      width: 90%;
      padding: 5px 10px;
      padding-bottom: 10px;
      border-radius: 5px;
      text-align: center;
      font-size: 16px;
      //   box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
      padding-right: 0px;
      margin-bottom: 20px;

      .chevRight {
        transition: all 0.1s linear;
        position: relative;
        top: 6.3px;
        // transform: translateY(-50%);
        margin: 0px 0px;
      }
    }
    .viewInProfile:hover {
      background-color: #49aaac;
      //   transform: scale(1.01);
      //   box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);

      .chevRight {
        transform: translateX(5px);
      }
    }

    .delete {
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.1s linear;
      background-color: #b43737;
      padding: 5px 10px;
      padding-bottom: 15px;
      text-align: center;
      width: 90%;
      border-radius: 5px;
      color: #fff;

      .editIcon {
        position: relative;
        top: 5px;
        margin: 0;
        margin-right: -7px;
      }
    }
    .delete:hover {
      background-color: #c43333;
    }
  }
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .containerQuestions {
    max-width: 1080px;
    margin: 0px auto;
    margin-top: 30px;
    text-align: left;
    height: auto;
    border-top: #4e9a9b solid 5px;
    // padding-bottom: 40px;

    .padding {
      padding: 15px;
      padding-bottom: 15px;
      background-color: #fff;
      position: relative;
      padding-bottom: 26px;

      .textBox {
        .textFlex {
          display: flex;
          flex-direction: row;
          h3 {
            font-weight: 300;
            font-size: 25px;
            margin: 8px;
          }

          h2 {
            font-weight: 500;
            font-size: 25px;
            margin: 0;
            border: none;
            padding: none;
          }
        }
      }
      .viewInProfile {
        width: 150px;
        border: none;
        // padding: 10px 30px;
        border-radius: 5px;
        // font-size: 18px;
        // box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
        padding: 5px 10px;
        padding-bottom: 10px;
        padding-right: 0px;
        position: absolute;
        // width: 100px;
        bottom: -9px;
        right: 156px;
      }

      .delete {
        padding: 5px 10px;
        padding-bottom: 5px;
        padding-bottom: 11px;
        margin: 0;
        width: 120px;
        position: absolute;
        // width: 100px;
        bottom: 10px;
        right: 10px;

        .editIcon {
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
