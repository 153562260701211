.containerQuestions {
  max-width: 1080px;
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  text-align: center;
  height: auto;
  border-top: #4e9a9b solid 2px;
  // padding-bottom: 40px;

  .padding {
    padding: 0px;
    background-color: #fff;

    h3 {
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 0px;
    }

    h2 {
      font-weight: 500;
      font-size: 15px;
      /* margin: 10px; */
      padding: 5px;
      line-height: 20px;
      margin-bottom: 20px;
      //   border: dashed grey 1px;
    }
  }
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .containerQuestions {
    max-width: 1080px;
    margin: 0px auto;
    margin-top: 30px;
    text-align: left;
    height: auto;
    border-top: #4e9a9b solid 2px;
    // padding-bottom: 40px;

    .padding {
      padding: 15px;
      padding-left: 30;
      padding-right: 30;
      background-color: #fff;
      position: relative;

      h3 {
        font-size: 25px;
      }
      h2 {
        font-weight: 500;
        font-size: 18px;
        border: none;
        line-height: 25px;
        // margin: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
