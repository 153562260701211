.submitButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #509b4e;
  border: none;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  width: 100px;
}

.submitButton:hover {
  background-color: #54af52;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.cancelButton {
  transition: all 0.1s linear;
  margin-top: 10px;
  margin-left: 8px;
  cursor: pointer;
  color: whitesmoke;
  background-color: #4e9a9b;
  border: none;
  width: 100px;
  padding: 20px 20px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
}

.cancelButton:hover {
  background-color: #49aaac;
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

.containerQuestions {
  max-width: 1080px;
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  text-align: center;
  height: auto;
  border-top: #4e9a9b solid 5px;
  // padding-bottom: 40px;

  .padding {
    padding: 30px;
    background-color: #fff;

    h3 {
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: 500;
      font-size: 20px;
      /* margin: 10px; */
      padding: 5px;
      line-height: 20px;
      margin-bottom: 40px;
      border: dashed grey 1px;
    }

    .edit {
      cursor: pointer;
      transition: all 0.1s linear;
      background-color: #529c51;
      padding: 5px 10px;
      padding-bottom: 15px;
      text-align: center;
      width: 90%;
      border-radius: 5px;
      color: #fff;
      margin-bottom: 10px;

      .editIcon {
        position: relative;
        top: 5px;
        margin: 0;
        margin-right: -7px;
      }
    }

    .edit:hover {
      background-color: #54af52;
    }

    .delete {
      cursor: pointer;
      transition: all 0.1s linear;
      background-color: #b43737;
      padding: 5px 10px;
      padding-bottom: 15px;
      text-align: center;
      width: 90%;
      border-radius: 5px;
      color: #fff;

      .editIcon {
        position: relative;
        top: 5px;
        margin: 0;
        margin-right: -7px;
      }
    }
    .delete:hover {
      background-color: #c43333;
    }
  }
}

.button {
  transition: all 0.1s linear;
  cursor: pointer;
  background-color: #4e9a9b;
  color: #fff;
  border: none;

  padding: 20px 30px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
  padding-right: 20px;
  margin-top: 20px;
  width: 100%;

  .chevRight {
    transition: all 0.1s linear;
    position: relative;
    top: 6.3px;
    // transform: translateY(-50%);
    margin: 0px 0px;
  }
}
.button:hover {
  background-color: #49aaac;
  transform: scale(1.01);
  box-shadow: 4px 4px 8px rgba(96, 96, 96, 0.7);
}

@media only screen and (min-width: 375px) {
}

@media only screen and (min-width: 414px) {
  .containerQuestions {
    max-width: 1080px;
    margin: 0px auto;
    margin-top: 30px;
    text-align: left;
    height: auto;
    border-top: #4e9a9b solid 5px;
    // padding-bottom: 40px;

    .padding {
      padding: 30px;
      background-color: #fff;
      position: relative;

      h3 {
        font-size: 25px;
      }
      h2 {
        font-weight: 500;
        font-size: 18px;
        border: none;
        line-height: 25px;
        // margin: 10px;
      }
      .edit {
        position: absolute;
        width: 100px;
        bottom: 10px;
        right: 140px;
        margin-bottom: 0px;

        .editIcon {
        }
      }

      .delete {
        position: absolute;
        width: 100px;
        bottom: 10px;
        right: 10px;

        .editIcon {
        }
      }
    }
  }

  .button {
    width: unset;
    border: none;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 3px 3px 8px rgba(96, 96, 96, 0.5);
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1580px) {
}
